import * as React from 'react';
import './productoTable.css';
import { Producto } from '../../model/producto';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../stores';
import * as ProductosStore from '../../stores/Productos';
import { useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useState } from 'react';

export interface IProps {
  EditProductEvent?: (product: Producto) => void | undefined;
}

export const ProductoTable = (props: IProps) => {
  const [showAlert, setShowAlert] = useState(false);

  const ProductState = useSelector(
    (state: ApplicationState) => state.productos
  );

  const AuthState = useSelector(
    (state: ApplicationState) => state.authentication
  );

  let history = useHistory();
  const dispatch = useDispatch();
  const editProducto = (producto: Producto) => {
    history.push('/productos/' + producto.id);
  };

  const [SearchText, setSearchText] = useState<string>('');

  const ViewProductoInfoHandler = (producto: Producto) => {
    history.push('/productos/info/' + producto.id);
  };

  const searchHandler = () => {
    dispatch(mapDispatchToProps.getAllProductos('0', '5000', SearchText));
  };

  const pressEnterHandler = (e: any) => {
    return e.key === 'Enter' ? searchHandler() : e;
  };

  const EditProductHandler = (product: Producto) => {
    if (props.EditProductEvent) props.EditProductEvent(product);
    else return null;
  };

  const DeleteProductHandler = (product: Producto) => {
    if (AuthState && AuthState.authenticatedUser) {
      dispatch(
        mapDispatchToProps.DeleteProduct(
          product.id,
          AuthState.authenticatedUser.jwToken
        )
      );
      setShowAlert(true);
    }
  };

  const removeFilterHandler = () => {
    setSearchText('');
  };

  const SearchBar = (
    <div className="searchBar">
      <div className="input-group">
        <input
          type="text"
          placeholder="Ingrese el texto a buscar..."
          className="form-control"
          defaultValue=""
          onKeyDown={pressEnterHandler}
          onChange={(e) => setSearchText(e.target.value)}
          value={SearchText}
        />
        {SearchText !== '' && (
          <button
            className="input-group-text"
            id="basic-addon2"
            onClick={removeFilterHandler}
          >
            X
          </button>
        )}
      </div>
      {/* <button className="btn btn-primary" onClick={searchHandler}>
        Buscar
      </button> */}
    </div>
  );

  const GettingSuccessfully = (
    <>
      <div>
        <h5>Productos registrados</h5>
        <div className="seccion">
          {SearchBar}
          <hr></hr>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Código</th>
                <th>Descripción</th>
                <th>Costo de Reposición</th>
                <th>Comentarios</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {ProductState &&
                ProductState.productos
                  .filter((product: Producto) =>
                    SearchText !== ''
                      ? product.codigo
                          .toLocaleLowerCase()
                          .indexOf(SearchText.toLocaleLowerCase()) !== -1 ||
                        product.nombre
                          .toLocaleLowerCase()
                          .indexOf(SearchText.toLocaleLowerCase()) !== -1
                      : true
                  )
                  .map((product: Producto) => (
                    <tr>
                      <td>
                        <strong className="upper">{product.codigo}</strong>
                      </td>
                      <td>
                        <label className="upper">{product.nombre}</label>
                      </td>
                      <td>
                        {product.replacementCost && <p>{product.replacementCost?.toLocaleString(undefined, {maximumFractionDigits:3}) + ' U$D'}</p>}
                      </td>
                      <td>
                        <p>{product.descripcion}</p>
                      </td>
                      <td>
                        <div className="controls">
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => EditProductHandler(product)}
                          >
                            Editar
                          </button>
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => ViewProductoInfoHandler(product)}
                          >
                            Información
                          </button>
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => editProducto(product)}
                          >
                            Ver fórmula
                          </button>
                          <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => DeleteProductHandler(product)}
                          >
                            Borrar
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {ProductState && ProductState.productos.length === 0 && (
            <label>No hay prodcutos registrados</label>
          )}
        </div>
      </div>
    </>
  );

  const GettingFail = ProductState?.failOnLoading && ProductState?.Error && (
    <div className="ErrorContainer">
      <label>{ProductState?.Error?.ErrorMessage}</label>
      <ul>
        {ProductState?.Error?.Errors.map((error: string) => (
          <li>{error}</li>
        ))}
      </ul>
    </div>
  );

  return (
    <>
      <div className="contentpage">
        {ProductState && ProductState.isFiltered && (
          <Alert variant="warning">
            Filtro aplicado: <strong>{ProductState.filterCriteria}</strong>
          </Alert>
        )}
        {ProductState && ProductState.failOnDelete && showAlert && (
          <Alert variant="danger" className="alert-dismissible stycky-top">
            <label>Atención</label>
            {ProductState.Error?.Errors && (
              <ul>
                {ProductState.Error.Errors.map((error: string) => (
                  <li>{error}</li>
                ))}
              </ul>
            )}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Cerrar"
              onClick={() => setShowAlert(false)}
            ></button>
          </Alert>
        )}
        {ProductState && ProductState.productos && GettingSuccessfully}
        {ProductState && ProductState.failOnLoading && GettingFail}
      </div>
    </>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  ...state.productos
});

const mapDispatchToProps = {
  ...ProductosStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductoTable as any);
