import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { WhiteSpaceComponent } from '../Commons/WhiteSpace/WhiteSpaceComponent';
import './formula.css';

function ListaPasosDrag({ data, update, buttons, isProducto }) {
  const SortableItem = sortableElement(({ value, orden }) => (
    <div className={!value.pausa ? 'itemFormula' : 'itemFormula pausa'}>
      {!value.pausa ? (
        <>
          <div>
            <h2>{value.materiaprima ? value.materiaprima.codigo : ''}</h2>
            <h4> {value.materiaprima ? value.materiaprima.nombre : ''}</h4>
            {/* <small>Proveedor</small>
            <h5>
              {value.materiaprima
                ? value.materiaprima.descripcionproveedor
                : ''}{' '}
              {value.materiaprima
                ? '(' +
                  (value.materiaprima.codigoproveedor
                    ? value.materiaprima.codigoproveedor
                    : 'Proveedor no informado') +
                  ')'
                : ''}
            </h5> */}
          </div>
          <div>
            <h1>
              {console.log('materia prima', value.materiaprima)}
              {value.valor}  
              {isProducto ? '%' : 'Kg'}
            </h1>
            {/* <span className="badge bg-secondary">Stock 234 Kg</span> */}
          </div>
          <div>
            <label>{'PR (x Kg): u$d ' + value.materiaprima?.precioReposicion || 0}</label>
            <label><strong>{'PR item: u$d ' + (((value.materiaprima?.precioReposicion) / 100) * value.valor).toLocaleString(undefined, {maximumFractionDigits:3}) || ' Inexistente'}</strong></label>
            </div>
          <div>
            {/* <small>Comentarios</small> */}
            <p>{value.observacion}</p>
          </div>
          {value.materiaprimaid &&
            value.materiaprima?.stock &&
            value.materiaprima?.stock?.cantidad <=
              value.materiaprima?.minimoStockAceptable &&
            value.materiaprima?.stock?.cantidad > 0 && (
              <div className="itemOrden">
                <h4>
                  {value.valor > value.materiaprima?.stock?.cantidad
                    ? 'Stock Insuficiente [' +
                      value.materiaprima?.stock?.cantidad +
                      ' ' +
                      (value.materiaprima?.unidadDeMedida?.codigo ||
                        'Unidad de medida no encontrada') +
                      ']'
                    : 'Stock Bajo'}
                </h4>
              </div>
            )}
          {value.materiaprima?.stock?.cantidad <=
            value.materiaprima?.minimoStockAceptable &&
            value.materiaprima?.stock?.cantidad <= 0 && (
              <div className="itemOrden">
                <h4>Sin Stock</h4>
              </div>
            )}
        </>
      ) : (
        <>
          <div>
            <h2>Disperción</h2>
          </div>
          <div>
            <h1>{value.minutospausa}m</h1>
          </div>
          <div>
            {/* <small>Comentarios</small> */}
            <p>{value.observacion}</p>
          </div>
          {/* <div className="itemOrden"><h4>{orden + 1}</h4>}</div> */}
        </>
      )}
      {buttons ? (
        <div className="itemControls">
          {buttons.map((b, index) => (
            <button
              onClick={() => b.btnFunc(value, orden)}
              className={b.btnclass}
              key={index}
            >
              {b.btnText}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  ));

  const SortableContainer = sortableContainer(({ children }) => {
    return <div className="listadoPasos">{children}</div>;
  });
  function onSortEnd({ oldIndex, newIndex }) {
    update(arrayMoveImmutable(data, oldIndex, newIndex));
  }

  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`;
  };
  return (
    <div className="listadoPasos">
      <SortableContainer onSortEnd={onSortEnd} helperClass="sortableHelper">
        {data.length > 0 ? (
          data.map((value, index) => (
            <SortableItem
              key={generateKey(value.id + '-' + index)}
              index={index}
              value={value}
              orden={index}
            />
          ))
        ) : (
          <>
            <WhiteSpaceComponent title={'No hay pasos informados'} />
          </>
        )}
      </SortableContainer>
    </div>
  );
}
export { ListaPasosDrag };
